@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'GroteskPro';
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/QuantaGroteskPro-Regular.ttf") format("truetype");
}
@font-face {
    font-family: 'GroteskPro';
    font-style: normal;
    font-weight: bold;
    src: url('./assets/fonts/QuantaGroteskPro-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'GroteskPro';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/QuantaGroteskPro-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'GroteskPro';
    font-style: italic;
    font-weight: normal;
    src: url('./assets/fonts/QuantaGroteskPro-MediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'GroteskPro';
    font-style: italic;
    font-weight: bold;
    src: url('./assets/fonts/QuantaGroteskPro-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'GroteskPro';
    font-style: italic;
    font-weight: 800;
    src: url('./assets/fonts/QuantaGroteskPro-ExtBdIta.ttf') format('truetype');
}

@font-face {
    font-family: 'GroteskPro';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/QuantaGroteskPro-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'GroteskPro';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/QuantaGroteskPro-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'GroteskPro';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/QuantaGroteskPro-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'GroteskPro';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/QuantaGroteskPro-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'GroteskPro';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/QuantaGroteskPro-MediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'GroteskPro';
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/QuantaGroteskPro-SemBdIta.ttf') format('truetype');
}

.ombra_viola{
    box-shadow: 10px 10px 0px 0px #8e9ebb;

}

.fixed-element {
    pointer-events: none;
}

.no-select {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

*{
    font-family: 'Inter', sans-serif;
    /*border: #f00 0.1px solid;
    /**/
}

h1{
    font-family: 'GroteskPro', sans-serif;
}

html{
    background-color: #000;

    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth; /* Prefisso per WebKit (Chrome, Safari, ecc.) */
    -moz-scroll-behavior: smooth; /* Prefisso per Firefox */
    -ms-scroll-behavior: smooth; /* Prefisso per Microsoft (Internet Explorer) */
  }

  img {
    pointer-events: none;
    -webkit-user-drag: none;
    user-drag: none;
  }

.bg-community{
    background-image: url('./assets/community.png');
    background-attachment: fixed;
}

.error {
    border: 1px solid #ff0000 !important;
    /*background-color: #bb807b67 !important;*/
  }

.error::placeholder{
    color: #4c5463 !important;
}



.animate-scroll{
    animation-name: scroll_animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;

}

@keyframes scroll_animation {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

/* CSS to hide default arrows and style the custom buttons */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
